
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { ItemType, Reserve, SaleOrderItem } from '@/entities/sales'
  import { dateToLocal, fixDate, fixPrice, intervalFormat, isValidNumber, paymentIcon } from '@/utils/general'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Deal, Lead } from '@/entities/crm'
  import { Payment } from '@/entities/finance'
  import dayjs from 'dayjs'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Debounce } from '@/utils/decorators'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import ValidationPaymentComponent from '@/components/forms/validationSaleOrder/validationPaymentComponent.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'

@Component({
  components: {
    GRadioButton,
    GCostField,
    GFiles,
    ValidationPaymentComponent,
    Agenda,
    GDatePicker,
    LinkedPerson,
    BaseForm,
  },
  methods: { fixPrice, paymentIcon },
  computed: {},
})
  export default class ValidateReserveForm extends GForm {
  reserve: Reserve = plainToInstance(Reserve, {})
  lead: Lead = plainToInstance(Lead, {})
  deal: Deal = plainToInstance(Deal, {})

  transferCost = 0
  process = null
  payments: Array<Payment> = []
  title = 'Reserva'
  openPayment = false
  paymentSelected = null
  idProcess = null
  idProcessReserve = null
  loadingFile = false
  photoProperties = {}
  showDetail = false
  minValue = null
  maxDays = null
  activity = null
  activities = []
  newAgenda = null
  openAgenda = false
  radioButtonMessage = {
    isValid: '',
  }

  formData = {
    commentLetter: '',
    isValid: null,
    client: null,
    discount: null,
    date: dayjs().format('DD/MM/YYYY'),
    order: null,
    comment: null,
    timeStart: null,
    timeEnd: null,
    paymentMethod: null,
    backup: [],
    discountPostFinancing: null,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldClient: LinkedPerson
  };

  metadata = {}
  metadataCollection = {}
  fields = {
    paymentMethods: {
      properties: {
        label: 'Formas de pago acordada',
        required: true,
        rules: this.fieldRequired,
        itemText: 'description',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    client: {
      properties: {
        required: true,
        disabled: true,
        properties: {
          label: 'Cliente',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          itemValue: 'id',
          returnObject: true,
        },
      },
      items: [],
      rules: [],
    },
    discount: {
      properties: {
        label: 'Descuento otorgado',
        required: true,
      },
      rules: this.fieldRequired,
    },
    date: {
      properties: {
        label: 'Fecha de entrega',
        required: true,
        rules: this.fieldRequired,
        maxMonths: 1,
        hint: '',
      },
    },
    order: {
      properties: {
        label: 'Abonos',
        required: true,
        readOnly: true,
        rules: this.fieldRequired,
      },
    },
    backup: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
      },
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setFileProperties()
    await this.getInfo()

    const { uid, id, model } = this

    if ((!isNaN(uid) && (model?.toLowerCase() === 'reserve' || model === '')) || !isNaN(id)) {
      const reserveId = id || uid
      await this.getReserveInfo(reserveId)
    }

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(this.getForm('Reserve', 'reserve'), 'Reserva', Boolean(!isNaN(uid)))
    }

    await this.setTheBackup()
    await this.setMetadata()
    this.loadingForm = false
  }

  async setFileProperties () {
    const idProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    this.idProcessReserve = idProcess[0]?.id
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { name: { _eq: 'signed_reservation' } }] },
    })

    const file = fileInfo[0]

    if (file) {
      Object.assign(this.fields.backup.properties, {
        accept: file.fileType.mimes,
        multiple: file.multiple,
        fileTypeId: file.fileType.id,
        name: file.name,
        label: file.description,
        required: file.required,
        icon: file.fileType.icon,
      })
    }
  }

  async getLeadInfo (parentModel, parentId, uid) {
    if (parentModel && parentId) {
      this.lead = await this.fetchData({
        query: { name: 'fetchLite', model: 'Lead', params: { id: parentId } },
        force: true,
      })
    }

    if (uid) {
      const leads = await this.fetchData({
        query: { name: 'find', model: 'Lead' },
        filter: { deals: { sale_orders: { reserve: { id: { _eq: uid } } } } },
        force: true,
      })
      this.lead = await this.fetchData({
        query: { name: 'fetchLite', model: 'Lead', params: { id: leads[0].id } },
        force: true,
      })
    }
  }

  async getInfo () {
    const { parentModel, parentId, id, model, uid } = this
    if ((!parentId && !parentModel) && (!model && !id && !uid)) return

    const lead = this.lead

    const discount = lead?.sale?.order?.discount
    const discountPostFinancing = lead?.sale?.order?.discountPostFinancing
    this.setLeadInfo(lead, discount, discountPostFinancing)
    const resp = await this.fetchData({
      query: { name: 'find', model: 'ActivityType' },
      filter: { name: { _eq: 'delivery' } },
    })

    this.activity = resp[0]

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.idProcess = process[0].id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    Object.assign(this.photoProperties, {
      accept: fileInfo[0].fileType.mimes,
      multiple: fileInfo[0].multiple,
      fileTypeId: fileInfo[0].fileType.id,
      name: fileInfo[0].name,
      label: fileInfo[0].description,
      required: fileInfo[0].required,
      icon: fileInfo[0].fileType.icon,
    })
    this.fields.paymentMethods.items = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
    })

    if (lead?.sale?.stock?.id) {
      this.transferCost = await this.calculateTransfer(lead.sale.stock?.id)
    }
    await this.getLeadInfo(parentModel, parentId, uid)
    await this.setDetails()
  }

  setLeadInfo (lead, discount, discountPostFinancing) {
    const { client } = lead as Lead
    if (discount?.value) {
      this.formData.discount = discount?.value
    }

    if (discountPostFinancing?.value) {
      this.formData.discountPostFinancing = discountPostFinancing.value
    }
    this.formData.client = client
    this.fields.client.items = [client]
  }

  async getReserveInfo (id) {
    const reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })
    this.reserve = reserve
    const projections = await this.fetchData({
      query: { name: 'find', model: 'ProjectionPayment' },
      filter: { id_sale_order: { _eq: reserve.saleOrder.id } },
      force: true,
    })
    this.formData.paymentMethod = projections.map(p => p.payment)
    await this.setFormInfo(reserve)
    if (reserve?.saleOrder?.discount?.value) {
      this.formData.discount = reserve.saleOrder.discount?.value
    }
    const { saleOrder: { deal: { lead: { id: leadId }, stock }, discount } } = reserve
    const lead = await this.fetchData({
      query: { name: 'fetchLite', model: 'Lead', params: { id: leadId.toString() } },
      force: true,
    })
    const backup = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: reserve.id } }, { parameter: { process: { id: { _eq: this.idProcessReserve } } } }, { parameter: { name: { _eq: 'signed_reservation' } } }] },
      force: true,
    })
    this.formData.backup = backup || []
    const discountPostFinancing = lead?.sale?.order?.discountPostFinancing
    this.setLeadInfo(lead, discount, discountPostFinancing)
    this.lead = lead as Lead
    await this.setDetails()
    this.transferCost = await this.calculateTransfer(stock?.id)
    await this.setDetails()
  }

  async setFormInfo (reserve) {
    const { saleOrder: { deliveryDate }, id, comment } = reserve

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })
    this.process = process[0]

    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_process: { _eq: process?.[0].id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    this.formData.date = dateToLocal(deliveryDate).format('DD/MM/YYYY')
    this.formData.timeStart = dateToLocal(deliveryDate).format('HH:mm')
    this.formData.timeEnd = dateToLocal(deliveryDate).add(1, 'hour').format('HH:mm')
    this.formData.comment = comment
  }

  async setMetadata () {
    const { metadata: metadataReserve } = this.getForm('Reserve', 'validator_reserve')

    const { fields: { config: { init: { value } } } } = metadataReserve as Form
    this.minValue = value.minValue
    this.maxDays = value.maxDays
    this.metadataCollection = metadataReserve
    await this.setDetails()
  }

  handleBackupPayment (backup) {
    const payment = 'payment' in backup && backup.payment

    const paymentForm = 'paymentForm' in backup && backup.paymentForm
    if (paymentForm) {
      const { paymentType, payer, amount, bank, reference, paymentDate, comment, paymentBackup } = paymentForm

      const pay = plainToInstance(Payment, {})

      pay.type = paymentType
      pay.payer = payer
      pay.reference = reference
      pay.amount = amount
      pay.issuingBank = bank
      pay.date = paymentDate
      pay.comment = comment
      pay.paymentBackup = paymentBackup

      this.payments.push(pay)

      if (!this.formData.order?.length) {
        this.formData.order = this.payments
      } else {
        this.formData.order.push(pay)
      }
      this.backup.paymentForm = null
      this.backup.payment = null
    }

    if (payment) {
      const pos = this.formData.order?.findIndex(order => order.id === payment.id)

      if (pos >= 0) {
        this.formData.order.splice(pos, 1, payment)
      }
    }
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup) return

    if (backup && 'reserveForm' in backup && backup.reserveForm) {
      this.formData = backup.reserveForm
    }

    this.handleBackupPayment(backup)

    const resp = await this.fetchData({
      query: { name: 'find', model: 'ActivityType' },
      filter: { name: { _eq: 'delivery' } },
    })

    this.activity = resp[0]
  }

  async goPerson () {
    const { formData, reserve } = this
    this.saveBackup()
    const idReserve = reserve?.id ? reserve?.id.toString() : 'created'
    const idPerson = formData.client?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Reserve', uid: idReserve, id: idPerson } })
  }

  async goToOrder () {
    const { deal } = this
    if (this.backup?.paymentForm) {
      this.backup.paymentForm = null
      this.backup.client = null
    }
    this.saveBackup()

    const idDeal = deal?.id ? deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  saveBackup () {
    const { backup, formData, lead, deal } = this
    if (backup) {
      backup.lead = lead
      backup.deal = deal
      backup.reserveForm = formData
      backup.paymentForm = null
      backup.client = null
      this.setBackup(backup)
    } else {
      this.setBackup({ reserveForm: formData, lead, deal, client: null })
    }
  }

  async handlePaymentRejected (orders) {
    const isPaymentToUpdate = orders.some(order => order.status.isToUpdating)
    if (isPaymentToUpdate) {
      const toUpdate = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'to_update' } } }] },
      })

      await this.pushData({
        model: 'Reserve',
        fields: {
          id: this.reserve.id,
          id_process_status: toUpdate[0].id,
        },
      })

      await this.close()
      return true
    }

    return false
  }

  isValidLetter () {
    const { formData: { isValid } } = this

    if (isValid === null) {
      this.radioButtonMessage.isValid = 'Campo requerido'
      return true
    }
    this.radioButtonMessage.isValid = ''
    return false
  }

  async send () {
    const client = this.$refs.fieldClient
    if (!this.$refs.form.validate() || !client.$refs.form.validate() || this.paymentError?.length) {
      return
    }
    this.loadingForm = true
    const { reserve, process } = this

    const orders = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: reserve?.id } },
          { id_process: { _eq: process?.id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    if (this.isValidLetter()) {
      this.loadingForm = false
      return
    }

    if (await this.handlePaymentRejected(orders)) {
      this.loadingForm = false
      return
    }

    if (!this.paymentValid(orders, true)) {
      this.loadingForm = false
      await this.close()
      return
    }

    const isAllPaymentsPaid = orders.every(order => (order.status.isClosed && order.closingReason.successful))

    if (isAllPaymentsPaid && !reserve?.closingReason) {
      await this.handleReserve(reserve)
    }

    if (!reserve?.closingReason) {
      await this.updateStock()
    }

    await this.close()
  }

  async handleReserve (reserve) {
    const { formData: { isValid, commentLetter } } = this

    if (this.formData.backup.length) {
      await this.insertFileProcessInfo({
        id: this.formData.backup[this.formData.backup.length - 1].id,
        comment: !isValid ? commentLetter : '',
        valid: isValid,
        validation_type: 'manual',
      })
    }

    if (!isValid) {
      const reserveStatusUpdate = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'to_update' } } }] },
      })
      await this.pushData({
        model: 'Reserve',
        fields: {
          id: reserve.id,
          id_process_status: reserveStatusUpdate[0].id,
        },
      })
      return
    }

    await this.pushData({
      model: 'SaleOrder',
      fields: {
        id: reserve.saleOrder.id,
        id_validator: this.idEmployee,
      },
    })

    const reserveStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    await this.pushData({
      model: 'Reserve',
      fields: {
        id: reserve.id,
        id_process_status: reserveStatus[0].id,
      },
    })
  }

  async updateStock () {
    const { lead: { sale: { stock: { id, status: stockStatus } } } } = this

    if (stockStatus?.isReservationProcess) {
      const status = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ status: { name: { _eq: 'reserved' } } }, { process: { table_name: { _eq: 'stock' } } }] },
      })
      const fields = {
        id,
        id_process_status: status[0].id,
      }

      await this.pushData({ model: 'Stock', fields })
      await this.publishStock(id)
    }
  }

  async updateDiscount (data, lead) {
    const { sale: { order } } = lead

    if (!isValidNumber(data.discount)) {
      return
    }

    if (!order?.discount) {
      const fields = {
        id_item_type: 2,
        quantity: 1,
        amount: data.discount,
        id_sale_order: order.id,
      }
      await this.pushData({ model: 'SaleOrderItem', fields })
    } else if (order.discount !== data.discount) {
      let fields
      const discount = order.saleOrderItems.filter(item => item.isDiscount)
      if (discount?.length && discount[0]?.id) {
        fields = {
          id: discount[0].id,
          amount: data.discount,
        }
      } else {
        fields = {
          id_item_type: 2,
          quantity: 1,
          amount: data.discount,
          id_sale_order: order.id,
        }
      }
      await this.pushData({ model: 'SaleOrderItem', fields })
    }
  }

  async updateReserve (date, startDate, endDate) {
    const { reserve, formData, lead: { sale: { order }, activity } } = this

    if (formData.comment !== reserve.comment) {
      const fields = {
        id: reserve.id,
        comment: formData.comment,
      }
      await this.pushData({ model: 'Reserve', fields })
    }

    if (!dayjs(formData.date).isSame(dateToLocal(order.deliveryDate))) {
      const fields = {
        id: order.id,
        delivery_date: date,
      }
      await this.pushData({ model: 'SaleOrder', fields })

      const task = {
        id: activity.id,
        scheduling_date: date,
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      }

      await this.pushData({ model: 'LeadActivity', fields: task })
    }
    const orders = formData.order?.filter(o => !o.id)

    if (orders.length) {
      await this.createPayment(reserve)
    }
    await this.insertUpdatePaymentType(reserve)
  }

  async insertReserve (date, startDate, endDate) {
    const now = dayjs()
    const reserve = await this.createReserve(date, startDate, endDate, now)
    await this.createPayment(reserve)
    await this.insertUpdatePaymentType(reserve)
  }

  async insertUpdatePaymentType (reserve) {
    const { formData: { paymentMethod } } = this

    const payments = await this.fetchData({
      query: { name: 'find', model: 'ProjectionPayment' },
      filter: { id_sale_order: { _eq: reserve.saleOrder.id } },
      force: true,
    })

    await Promise.all(paymentMethod.map(async item => {
      const exist = payments?.find(p => p.payment.id === item.id)

      const fields = {
        id_sale_order: reserve.saleOrder.id,
        id_payment_type: item.id,
      }
      if (!exist) {
        await this.pushData({
          model: 'ProjectionPayment',
          fields,
        })
      }
    }))
    const deletePayments = payments.filter(p => !paymentMethod.map(pm => pm.id).includes(p.payment.id))

    await Promise.all(deletePayments.map(async item => {
      await this.removeData({ model: 'ProjectionPayment', fields: { id: item.id } })
    }))
  }

  async createReserve (date, startDate, endDate, now) {
    const { lead: { sale: { order } }, maxDays, lead, activity, formData } = this

    const task = {
      id_activity_type: activity.id,
      id_lead: lead?.id,
      id_process_status: 13,
      scheduling_date: date,
      duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
    }

    await this.pushData({ model: 'LeadActivity', fields: task })

    const saleFields = {
      id: order.id,
      delivery_date: date,
    }
    await this.pushData({ model: 'SaleOrder', fields: saleFields })

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ default: { _eq: true } }, { process: { table_name: { _eq: 'reserve' } } }] },
    })

    const fields = {
      id_process_status: status[0].id,
      expiration: now.add(maxDays, 'days'),
      id_sale_order: order.id,
      comment: formData.comment,
    }

    return this.pushData({ model: 'Reserve', fields })
  }

  async createPayment (process) {
    const { formData, deal, photoProperties, idProcess } = this

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ default: { _eq: true } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const processValue = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    await Promise.all(formData.order.filter(o => !o.id).map(async item => {
      const fields = {
        id_payment_type: item.type.id,
        id_process: processValue[0].id,
        id_process_record: process.id,
        id_process_status: status[0].id,
        amount: item.amount,
        reference: item.reference,
        date: fixDate(item.date),
        id_payer: item.payer.id,
        id_issuing_bank: item?.issuingBank?.id,
        comment: item.comment,
        id_deal: deal.id,
      }
      if (!fields?.reference) {
        delete fields.reference
      }
      if (!fields?.id_issuing_bank) {
        delete fields.id_issuing_bank
      }

      const pay = await this.pushData({ model: 'Payment', fields })
      if (item.paymentBackup.length) {
        await this.handleFileType(item.paymentBackup, { properties: photoProperties }, idProcess, pay.id)
      }
    }))
  }

  async setDetails () {
    const { metadataCollection, lead, formData: { discount }, transferCost, totalAmount, reserve } = this

    const deal = lead.deals?.find(deal => deal.isSale)
    if (!deal) return

    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'discount')

    if (!pos || pos < 1) {
      deal.saleOrders[0].saleOrderItems.push(plainToInstance(SaleOrderItem, {
        amount: discount,
        type: plainToInstance(ItemType, { id: 2, name: 'discount' }),
      }))
    } else {
      deal.saleOrders[0].saleOrderItems[pos].amount = discount
    }

    deal.lead = lead
    deal.lead.sale.stock.transferCost = transferCost
    deal.lead.sale.totalAmount = totalAmount
    deal.stock.transferCost = transferCost
    deal.auto = deal.lead.sale.auto
    deal.stock.transferCost = transferCost
    deal.totalAmount = totalAmount
    this.deal = deal
    deal.order.reserve = reserve

    this.metadata = {
      data: deal,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(deal.id)
  }

  async getActivitiesByLead () {
    const { idEmployee, formData: { date } } = this

    const filter = {
      _and: [
        {
          lead: {
            id_executive: {
              _eq: idEmployee,
            },
          },
        }, {
          id_closing_reason: {
            _is_null: true,
          },
        }, {
          activity_type: { name: { _neq: 'inspection' } },
        }, {
          scheduling_date: {
            _gte: dayjs(fixDate(date)).startOf('day'),
            _lte: dayjs(fixDate(date)).endOf('day'),
          },
        }],
    }

    return this.fetchData({
      query: {
        name: 'find',
        model: 'LeadActivity',
      },
      filter,
      force: true,
    })
  }

  get discount () {
    const { lead: { sale } } = this
    if (!sale) return 0
    const { stock } = sale

    return stock?.price?.bonus?.amount || stock?.price?.margin || 0
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get paymentError () {
    const { minValue, formData: { order } } = this
    if (!order) return ''
    return this.paymentValid(order) ? '' : `La suma de los pagos debe ser mayor ${fixPrice(minValue)}`
  }

  get isReserveActive () {
    const { reserve } = this

    return Boolean(reserve?.status?.isActive)
  }

  get discountIsPending () {
    const { lead: { sale } } = this
    if (!sale) return false
    const { financial } = sale
    if (!financial) return false
    const { evaluation } = financial

    return evaluation.status.isPending
  }

  paymentValid (payments, paid = false) {
    const { minValue } = this

    if (!paid) {
      const suma: number = payments.reduce((total, item) => {
        return total + Number(item.amount)
      }, 0)

      return suma >= minValue
    }

    const suma: number = payments.filter(payment => payment.status.isClosed && payment.closingReason.successful).reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma >= minValue
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    if (!range.start && !range.end) return
    this.formData.timeStart = range.start
    this.formData.timeEnd = range.end
  }

  get typeChange () {
    const { activity, formData: { date }, openAgenda } = this
    return {
      activity,
      date,
      openAgenda,
    }
  }

  @Watch('typeChange', { immediate: true, deep: true })
  async onDateChange ({ activity, date }) {
    if (!activity || !date) return null
    const { lead } = this

    this.newAgenda = { title: activity, date, allowSelect: true, lead }
    await this.setDetails()
  }

  @Watch('formData.discount', { immediate: true })
  @Debounce(100)
  onDiscountChange () {
    this.setDetails()
  }

  async handleAgenda () {
    this.activities = await this.getActivitiesByLead()

    this.newAgenda = { title: this.typeChange.activity, date: this.typeChange.date, allowSelect: true, lead: this.lead }
    this.openAgenda = true
  }

  async editPayment (item) {
    this.openPayment = true
    this.paymentSelected = item
  }

  get discountAuthorized () {
    const { reserve: { saleOrder } } = this

    if (!saleOrder) return 0

    return (saleOrder?.discount?.value || 0)
  }

  get priceList () {
    const { deal } = this

    if (!deal) return 0
    return deal?.stock?.price?.amount || 0
  }

  get totalAmount () {
    const { discountAuthorized: discount, priceList, transferCost } = this

    return priceList + transferCost - discount
  }

  @Watch('openPayment', { immediate: true })
  async onOpenPaymentChange (val) {
    const { reserve, process } = this
    if (!val && reserve?.id && process) {
      this.paymentSelected = null
      this.formData.order = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { id_process_record: { _eq: reserve?.id } },
            { id_process: { _eq: process?.id } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
        force: true,
      })
    }
  }
  }
